import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactHighcharts from 'react-highcharts';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import FormControl from '../../../components/FormControls/FormControl';
import sprite from '../../../assets/images/sprite_base.svg';
import moment from 'moment';
import PatientReportDownloadTemplate from '../../Visits/components/PatientReportDownloadTemplate';
import PatientReportDownloadTemplatePast from '../../Visits/components/PatientReportDownloadTemplatePast';
import { addMonths } from 'date-fns';
import LoadingTemplate from '../../../components/LoadingTemplate';
import { roundNumber } from '../../Analytics/tools/helpers';
const openbleFiles = ['pdf', 'jpg', 'png', 'jpeg'];
const _ = require("lodash");

const patientDetails = {
    name: labels.patient_list_labels.name_label,
    displayDate: labels.inbox_labels.dob,
    identifier: labels.oddities_labels.identifier,
    gender: labels.patient_list_labels.gender_label,
    site: labels.patient_list_labels.site_label,
    visits: labels.sites_labels.visits_label,
}
const patientDetailsValidation = {
    name: labels.not_available,
    displayDate: labels.not_available,
    identifier: '',
    gender: labels.not_available,
    site: labels.not_available,
    visits: '0'
}

class ViewPatient extends Component {
    componentDidMount() {
        this.props.setFirstTimeLoad(true)

        if (this.props.match.params && this.props.match.params.patientId && !isNaN(this.props.match.params.patientId)) {
            this.props.setCurrentPatient(this.props.match.params.patientId, true)
        }
        else {
            this.props.history.push('/patients/list')
        }
        if (this.newEmail && this.newEmail.inputEntry)
            this.newEmail.inputEntry.focus()
    }
    componentWillUnmount() {
        this.props.noHeaderLoading(false)
        this.props.setFirstTimeLoad(false)

    }
    // getVisitAge(visitDate, dob) {
    //     let yrs = parseInt(moment(visitDate).diff(dob, 'years'))
    //     if (yrs)
    //         return yrs
    //     else
    //         return 3
    // }
    getVisitAge(dob, visitdate) {
        // return Math.floor(Math.abs(moment(visitdate).diff(dob, 'years', true)))
        return roundNumber(Math.abs(moment(visitdate).diff(dob, 'years', true)), 1)
    }
    getAggregateUrl(ageData, currentPatient, visitDate) {
        let aggregateAges = {}
        // delete ageData.incite_trubiohealth_age
        Object.keys(ageData).map(m => {
            Object.keys(constants.biographical_labels.ageLabels).map(a => {
                let key = constants.biographical_labels.ageLabels[a]
                if (m.toLowerCase() == a.toLowerCase()) {
                    if (aggregateAges[key]) {
                        aggregateAges[key].push(ageData[m] != "0" ? ageData[m].toString() : "")
                    } else {
                        aggregateAges[key] = [];
                        aggregateAges[key].push(ageData[m] != "0" ? ageData[m].toString() : "")
                    }
                }
            })
        })
        if (aggregateAges.patientAges) {
            aggregateAges.patientAges.push(this.getVisitAge(currentPatient.dob, visitDate))
        } else {
            aggregateAges.patientAges = []
            aggregateAges.patientAges.push(this.getVisitAge(currentPatient.dob, visitDate))
        }
        let aggregateAgeUrl = constants.biographical_labels.artisan_route
        let res = Object.keys(_.omit(aggregateAges, ['patientAges'])).map(agg => {
            // if (agg != "TruBioHealth Age") {
            var ages = aggregateAges[agg].map(s => {
                if (s == "0") {
                    return s = ""
                }
                else {
                    return s
                }
            })
            if (_.compact(ages).length > 0)
                return `n:${agg}^v:${(ages).join(',')}^b:${aggregateAges.patientAges.join(',')}`
            else return null
            // }
        })

        return `${aggregateAgeUrl}?data=${_.compact(res).join('|')}`

    }
    getBiomakersConfig(rowData, dob, isModal) {

        if (rowData) {
            return {
                chart: {
                    type: 'column',
                    backgroundColor: 'rgba(255, 255, 255, 0.0)'
                },
                title: {
                    text: isModal ? rowData.timePoint : ''
                },
                tooltip: {
                    enabled: false
                },
                xAxis: {
                    categories: rowData && rowData.ageData ? Object.keys(rowData.ageData).filter(function (r) {
                        return rowData.ageData[r] != 0
                    }) : [],
                    crosshair: true,
                    labels: {
                        enabled: isModal ? true : false
                    },
                    tickLength: 0,
                    plotLines: [{
                        color: isModal ? '#000000' : '#cccccc',
                        width: isModal ? 2 : 4,
                        value: -0.5
                    }]
                },
                yAxis: {
                    gridLineWidth: isModal ? 1 : 0,
                    minorGridLineWidth: isModal ? 1 : 0,
                    min: 0,
                    title: {
                        text: ''
                    },
                    labels: {
                        enabled: isModal ? true : false
                    },
                    tickLength: 0,
                    plotLines: [{
                        color: isModal ? '#000000' : '#cccccc',
                        width: isModal ? 2 : 4,
                        value: this.getVisitAge(rowData.visitDate, dob)
                    }]
                },
                plotOptions: {
                    column: {
                        threshold: this.getVisitAge(rowData.visitDate, dob)
                    },
                    series: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                series: [{
                    data: rowData && rowData.ageData ? Object.values(rowData.ageData).map(a => {
                        if (a != 0)
                            return a
                        else
                            return 0
                    }).filter(function (a) { return a }) : [],
                    color: '#bb0000',
                    negativeColor: '#589900',
                    enableMouseTracking: isModal ? true : false,
                }]
            }
        } else {
            return {}
        }
    }
    render() {
        const columns = [
            {
                dataField: 'timepoint',
                text: labels.billing_labels.timepoint,
                formatter: (cellContent, row) => (<div className='time-point-col'><Link to={`/visits/${row.id}`}>{row.timePoint}</Link><p>{row.dateOfVisit}</p></div>),
            },
            {
                dataField: 'dataEntry',
                text: labels.patient_list_labels.data_entry,
                formatter: (cellContent, row) => <span className='patient-visit-data-entry'>{row.dataEntry && row.dataEntry.length
                    ? row.dataEntryDTO.map(de => (de.shouldLoad ? <i className='fa fa-spinner fa-spin expositer-loader'></i> : de.dataEntry ? <i className='fa fa-check success'></i> : <i className='fa fa-times danger'></i>)) : null}</span>
            },
            {
                dataField: 'ageData',
                text: labels.analytics_labels.biomarkers_label,

                formatter: (cellContent, row) => {
                    let aggregateUrl = this.getAggregateUrl(row.ageData, this.props.currentPatient, row.visitDate)
                    let isUrl = row.ageData && Object.keys(row.ageData).length && Object.keys(row.ageData).filter(d => row.ageData[d]).length
                    return <img className={isUrl ? 'cursor-pointer' : 'no-cursor'} onClick={() => {
                        if (isUrl)
                            window.open(aggregateUrl, '_blank')
                    }}
                        src={aggregateUrl} />


                    // <span className={row.ageData && Object.keys(row.ageData).length ? 'cursor-pointer' : 'no-cursor'}
                    //     onClick={() => { this.props.toggleBiomarkersModal(row.id) }}>
                    //     {delete row.ageData.incite_trubiohealth_age}
                    //     <ReactHighcharts
                    //         config={this.getBiomakersConfig(row, this.props.currentPatient
                    //             ? this.props.currentPatient.dob : new Date())}>
                    //     </ReactHighcharts>
                    // </span>
                }
            },
            {
                text: labels.analytics_labels.download_label,
                formatter: (cellContent, row) => (<div className="d-flex flex-column"><a href='javascript:void(0)' className="mb-3" onClick={() => { this.props.downloadPatientReport(row.id, this.history) }}><i className="fa fa-file-pdf-o" aria-hidden="true"></i> {labels.visit_page_labels.download_current_link}</a> <a className={`${row.timePoint == constants.baseline ? "d-none" : ""}`} href='javascript:void(0)' onClick={() => { this.props.downloadPatientReport(row.id, this.history, true) }}><i className="fa fa-file-pdf-o" aria-hidden="true"></i> {labels.visit_page_labels.download_past_link}</a></div>)
            },
        ]
        const documents = [
            {
                dataField: '', text: labels.document_labels.format_published, formatter: (cellcontent, row) => row.format == 'pdf' ?
                    <i class="fa fa-file-pdf-o "> {row.format}</i> : <i class="fa fa-file-o "> {row.format}</i>
            },
            {
                dataField: 'title', text: labels.document_labels.title,
                formatter: (cellcontent, row) => {
                    if (row.format && openbleFiles.indexOf(row.format.toLowerCase()) != -1) {
                        return <Link to={`/documents/${row.documentId}.${row.format}`} target='_blank'>{row.title}</Link>
                    } else {
                        return <a onClick={() => this.props.downloadDocument(row, this.props.history)}>{row.title}</a>
                    }
                }
            },
            { dataField: 'followUpName', text: labels.document_labels.visit },
            { dataField: 'publicationDate', text: '', formatter: (cellcontent, row) => <span>{moment(row.publicationDate).format('MM/DD/YYYY')}</span> },
            {
                dataField: '', text: '', formatter: (cellcontent, row) => <span className='delete-document' onClick={() => this.props.deleteDocument(row.documentId)}>
                    <svg aria-hidden="true" title="Delete" fill="red">
                    <use xlinkHref="#trash" xmlnsXlink="http://www.w3.org/1999/xlink"></use>
                    <symbol id="trash" viewBox="0 0 16 16">
                        <path d="M2,6v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V6H2z"></path>
                        <path d="M12,3V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H0v2h16V3H12z M10,3H6V2h4V3z"></path>
                    </symbol>
                </svg></span>
            }
        ]
        // handle any data change on table here
        return (
            this.props.isLoading && this.props.isFirstTimeLoad ? <><LoadingTemplate haveSidebar={true} isDetails={true} />
                {/* {this.props.isPatientReport ?
                    <div className='display-none'>
                        <PatientReportDownloadTemplate {...this.props} />
                    </div> : null
                } */}
            </> :
                <>
                    <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2'>
                        <div className='view-patient-btn-sec'>
                            <FormControl type='button' btnType='primary' text={labels.patient_list_labels.add_visit}
                                onClick={() => {
                                    this.props.toggleVisitModal(this.props.match.params.patientId); setTimeout(() => {
                                        // this.newVisit.inputEntry.focus()
                                        if (document.getElementById('create_visit'))
                                            document.getElementById('create_visit').focus()
                                    });
                                }} />

                            <FormControl type='button' btnType='default' text={labels.patient_list_labels.edit_Patient}
                                onClick={() => this.props.navigatePatient(this.props.history, `/patients/edit/${this.props.currentPatient.id}`)} />
                            <FormControl type='button' className='btn-sm visible-xs' btnType='primary' text={labels.patient_list_labels.view_health_analytics}
                                onClick={() => this.props.navigateHealthAnalytics(this.props.history, `/patients/${this.props.match.params.patientId}/analytics`)} />  
                                
                            {(this.props.loggedInUser.role == constants.logged_roles.AN ||
                                (this.props.loggedInUser.role == constants.logged_roles.CG && this.props.loggedInUser.can_remove_patients && this.props.loggedInUser.primarySiteId == this.props.currentPatient.siteId))
                                ? (<FormControl id="patientDeleteBtn" type='button' btnType='danger' text={labels.patient_list_labels.delete_patient}
                                    onClick={() => { this.props.deletePatient(true) }}></FormControl>) : null}
                        </div>
                        <div className='card card-default patient-details-section'>
                            <div className='card-header'>
                                {labels.patient_list_labels.patient_details}
                            </div>
                            <div className='card-body'>
                                {this.props.currentPatient ? <table>
                                    {Object.keys(patientDetails).map(pd => (
                                        pd != 'site' ? <tr>
                                            <td className='font-bold text-right'>{patientDetails[pd]}</td>
                                            <td className='text-left break-word'>{this.props.currentPatient[pd] ? this.props.currentPatient[pd] : patientDetailsValidation[pd]}</td>

                                        </tr> :
                                            <tr>
                                                <td className='font-bold text-right'>{patientDetails[pd]}</td>
                                                <td className='text-left break-word'><Link to={`/sites/${this.props.currentPatient.siteId}`}>{this.props.currentPatient ? this.props.currentPatient[pd] : ''}</Link></td>
                                            </tr>
                                    ))}
                                </table> : null}
                            </div>
                        </div>
                        <div className='card update-email'>
                            <div className='card-header info'>
                                {labels.patient_list_labels.patient_portal}
                            </div>
                            <div className='card-body'>
                                {this.props.currentPatient.isUser ?
                                    <React.Fragment>
                                        <span ><p>{labels.patient_list_labels.portal_help}
                                            {this.props.currentPatient.lastLoggedInAt == labels.patient_list_labels.never ? <b>{labels.patient_list_labels.portal_caution}</b>
                                                : <b>{labels.patient_list_labels.portal_last_viewed} {this.props.currentPatient.lastLoggedInAt} </b>}</p>
                                        </span>
                                        <FormControl type='button' btnType='primary'
                                            disabled={!(this.props.currentPatient.visits)}
                                            text={labels.patient_list_labels.view_patient_portal}
                                            onClick={() => this.props.navigateHealthAnalytics(this.props.history, `/patients/${this.props.match.params.patientId}/analytics`)} />
                                        <FormControl type='button' btnType='default' text={labels.patient_list_labels.resend_email} onClick={() => this.props.resendWelcomeEmail(this.props.history)} />
                                        {/* disabled={this.props.currentPatient.lastLoggedInAt != 'Never'}  */}
                                    </React.Fragment> :
                                    (this.props.currentPatient && this.props.currentPatient.email ?
                                        (this.props.currentPatient.visits > 0 ?
                                <React.Fragment>
                                    <p>{labels.patient_list_labels.enable_portal_caution}</p>
                                    <FormControl type='button' btnType='primary' text={labels.patient_list_labels.enable_patient_portal} onClick={() => this.props.enablePatientPortal()} />
                                </React.Fragment> : <React.Fragment>
                                    <p>{labels.patient_list_labels.no_visit_caution}</p>
                                </React.Fragment>) :
                                <React.Fragment >
                                    <form onSubmit={(e) => { e.preventDefault(); this.props.saveNewEmail() }}>
                                        <p>{labels.patient_list_labels.portal_mail_help}</p>
                                        <FormControl type='email'
                                            validate
                                            required='true'
                                            id='newemail'
                                            onChange={this.props.editPatient} label={labels.patient_list_labels.email}
                                            ref={(em) => this.newEmail = em} />
                                        <FormControl type='button' btnType='primary' text={labels.patient_list_labels.update_patient} />
                                    </form>
                                </React.Fragment>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 order-1'>
                        {this.props.currentPatient && this.props.currentPatient.visits ?

                            <React.Fragment>
                                {this.props.currentPatient.visitsData && this.props.currentPatient.visitsData.map(s => s.dataEntryDTO && s.dataEntryDTO.length ? s.dataEntryDTO.filter(t => t.shouldLoad == true) : []).filter(function (func) { return func.length }).length ?
                                    <div class="alert alert-info">
                                        <i className='fa fa-gear fa-spin expositer-loader-header'></i> Results are getting exposited, please reload to view the exposited results!!
                                    </div> : ''}
                                <div className='patient-analytics-nav-sec hidden-xs'>
                                    <div className='col-lg-3 col-md-4 card ml-0 navigate-analytics'>
                                        <svg className='cursor-pointer' onClick={() => this.props.navigateHealthAnalytics(this.props.history, `/patients/${this.props.match.params.patientId}/analytics`)}>
                                            {/* <use xlinkHref={`${sprite}#analytics`}></use> */}
                                            <use xlinkHref="#analytics"></use>
                                            <symbol id="analytics" viewBox="0 0 48 48" >
                                                <path d="m 4.9943573,41.936434 c -2.6832033,-3.80025 -4.25942778,-8.43791 -4.25942778,-13.44371 0,-12.88747 10.44735848,-23.33483 23.33482948,-23.33483 12.887471,0 23.334828,10.44736 23.334828,23.33483 0,4.99401 -1.568815,9.62162 -4.240504,13.41688" fill="none" stroke-width="2" stroke="blue" />
                                                <path d="M 7,31 L 17,26 L 30,28 L 40,19" fill="none" stroke-width="1" stroke="grey"></path>
                                                <circle cx="7" cy="31" r="1" fill="white" stroke-width="0.5" stroke="blue"></circle>
                                                <circle cx="17" cy="26" r="1" fill="white" stroke-width="0.5" stroke="blue"></circle>
                                                <circle cx="30" cy="28" r="1" fill="white" stroke-width="0.5" stroke="blue"></circle>
                                                <circle cx="40" cy="19" r="1" fill="white" stroke-width="0.5" stroke="blue"></circle>
                                            </symbol>
                                        </svg>
                                        <FormControl type='button' className='btn-sm' btnType='default' text={labels.patient_list_labels.view_health_analytics}
                                            onClick={() => this.props.navigateHealthAnalytics(this.props.history, `/patients/${this.props.match.params.patientId}/analytics`)} />
                                        {this.props.loggedInUser.role == constants.logged_roles.AN ? 
                                        <FormControl type='button' className='btn-sm' btnType='default' text={labels.patient_list_labels.view_demo_analytics}
                                            onClick={() => this.props.navigateHealthAnalytics(this.props.history, `/patients/${this.props.match.params.patientId}/analytics/demo`)} />    
                                        : null }    
                                    </div>
                                    <div className='col-lg-3 col-md-3 card panel-info'>
                                        <svg>
                                            {/* <use xlinkHref={`${sprite}#${this.props.currentPatient ? this.props.currentPatient.gender : 'male'}`}></use> */}
                                            <use xlinkHref={`#${this.props.currentPatient ? this.props.currentPatient.gender : 'male'}`}></use>
                                            <symbol id="female" viewBox="0 0 48 48">
                                                <g class="nc-icon-wrapper"><path fill-rule="evenodd" clip-rule="evenodd" fill="#C6A279" d="M16.99757,31C13.6852036,31,11,28.3252907,11,25V14 c0-7.1800003,5.8199997-13,13-13s13,5.8199997,13,13v11c0,3.3155499-2.6845322,6-5.99757,6H16.99757z"></path> <rect x="18" y="23" fill-rule="evenodd" clip-rule="evenodd" fill="#EAC3A2" width="12" height="17"></rect> <path fill-rule="evenodd" clip-rule="evenodd" fill="#E0E0E0" d="M40.6829987,38.3419991L30,33l-6,6l-5.9820004-6 L7.3169999,38.3419991C5.2839999,39.3580017,4,41.4360008,4,43.7080002v2.2864037C4,46.549778,4.4492188,47,5.0087748,47h37.9824448 C43.5483589,47,44,46.5560265,44,45.9944038v-2.2864037C44,41.4360008,42.7159996,39.3580017,40.6829987,38.3419991z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#F1D9C5" d="M13,16v2c0,6.0650005,4.9350014,11,11,11s11-4.9349995,11-11v-2 c0-1.6568508-1.3431473-3-3-3c-3.4829292,0-6.5037708-1.9784393-8-4.8727989C22.5037708,11.0215607,19.4829292,13,16,13 C14.3431501,13,13,14.3431492,13,16z"></path></g>
                                            </symbol>
                                            <symbol id="male" viewBox="0 0 48 48">
                                                <g class="nc-icon-wrapper"><rect x="18" y="24" fill-rule="evenodd" clip-rule="evenodd" fill="#EAC3A2" width="12" height="15"></rect> <path fill-rule="evenodd" clip-rule="evenodd" fill="#F1D9C5" d="M35,12v8c0,6.0650005-4.9349995,11-11,11s-11-4.9349995-11-11v-8 H35z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#72C472" d="M46,44.0092545 c0-1.6619644-1.2289314-3.5469093-2.7504997-4.2125969L30,34c-1.6879997,1.25-3.7539997,2-6,2s-4.3120003-0.75-5.9820004-2 L4.753592,39.7966576C3.232825,40.4612465,2,42.3496704,2,44.0092545v2.9878159C2,47.550972,2.4498155,48,3.001672,48h41.9966583 C45.5515404,48,46,47.5621185,46,46.9970703V44.0092545z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#444444" d="M13,11.9989014 c0-3.3131027,2.5352898-6.9043617,5.6539803-8.0181808l7.4057007-2.6448898 c0.5193291-0.1854706,1.1393986,0.0623283,1.3841782,0.551899L29,5c3.3137093,0,6,2.6930313,6,6v6c0-2.7614212-2.2385788-5-5-5H18 c-2.7614202,0-5,2.2385788-5,5V11.9989014z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M24,36c-2.2459717,0-4.3120117-0.75-5.9819946-2 l-0.0055542,0.0024414l0,0l-2.4395752,1.0661011l1.9101353,4.6680298c0.2560425,0.625721,1.0463161,0.8223228,1.5656967,0.3895035 L24,36l4.9513283,4.1261063c0.519371,0.4328079,1.3096256,0.2362289,1.5656853-0.389473l1.9126129-4.673645L30,34 C28.3120117,35.25,26.2460327,36,24,36z"></path></g>
                                            </symbol>
                                        </svg>
                                        <FormControl type='button' className='btn-sm' btnType='default' text={labels.patient_list_labels.edit_Patient}
                                            onClick={() => this.props.navigatePatient(this.props.history, `/patients/edit/${this.props.currentPatient.id}`)} />
                                    </div>
                                    <div className='col-lg-5 col-md-5 card aggregate-age-chart panel-info'>
                                        <div className='card-header info'>
                                            {labels.patient_list_labels.agg_age_chart}
                                        </div>
                                        <iframe src={this.props.currentPatient.aggregateAgeUrl}>
                                        </iframe>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0'>

                                    <div className='card card-visit mb-2' >
                                        <div className='card-body'>
                                            <h3>{labels.sites_labels.visits_label}</h3>
                                        </div>
                                        <div className='patients-table patients-visits-list'>
                                            {this.props.currentPatient && this.props.currentPatient.visitsData ?
                                                <BootstrapTable className='table'
                                                    striped={false}
                                                    bordered={false}
                                                    hover={true}
                                                    bootstrap4
                                                    keyField='id'
                                                    data={this.props.currentPatient.visitsData ?
                                                        _.orderBy(this.props.currentPatient.visitsData, 'visitDate', 'desc') : ''}
                                                    columns={columns}
                                                /> : this.props.no_grid_data ?
                                                    <div className='jumbotron'>
                                                        <h3>{labels.no_grid_data}</h3>
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                    {this.props.currentPatient && this.props.currentPatient.enableDocuments ?
                                        this.props.currentPatientDocuments && this.props.currentPatientDocuments.length ?
                                            <div className='card card-visit mb-2' >
                                                <div className='card-body'>
                                                    <h3>{labels.document_labels.patient_downloads}</h3>
                                                </div>
                                                <div className='patient-documents'>
                                                    <BootstrapTable striped={true} bordered={false} hover={true} bootstrap4 keyField='id'
                                                        data={this.props.currentPatientDocuments ? this.props.currentPatientDocuments : []} columns={documents}
                                                    />
                                                </div>
                                                <p className='ml-2'>{labels.document_labels.documents_text}</p>
                                            </div>
                                            :
                                            <div className='card card-visit'>
                                                <div className='card-body b-b-light'> 
                                                 <h3>{constants.no_doc_preview_patients.header}</h3>
                                                </div>
                                                <div className='patient-jumb jumbotron'>
                                                    <p>{constants.no_doc_preview_patients.first_paragraph}</p>
                                                    <ul>
                                                        {
                                                            constants.no_doc_preview_patients.list.map(s =>
                                                                <li>{s}</li>
                                                            )
                                                        }
                                                    </ul>
                                                    <p>{constants.no_doc_preview_patients.third_paragraph}</p>
                                                    <p>{constants.no_doc_preview_patients.add_your_own} <a href={`/sites/${this.props.currentPatient.siteId}/documents`}>Document Library</a> {constants.no_doc_preview_patients.fourth_paragraph}</p>
                                                    {/* <p>{constants.no_doc_preview_patients.first_paragraph}</p> */}
                                                    <FormControl type='button' text={constants.no_doc_preview_patients.upload_a_doc} onClick={() => this.props.history.push(`/patients/${this.props.currentPatient.id}/documents/new`)} className="btn btn-primary btn-huge" />
                                                </div> 
                                            </div>
                                        : ''}
                                </div>
                            </React.Fragment> : this.props.no_grid_data ? <React.Fragment>
                                <div className='jumbotron'>
                                    <h1 className='mb-2'>{labels.no_visits}</h1>
                                    <h5>{labels.patient_list_labels.no_visits_patients}</h5>
                                </div>
                            </React.Fragment> : null
                        }
                        {this.props.isPatientReport && this.props.patientReportDownloadTemplateSection ?
                            <div className='display-none reports-download-section'>
                                <PatientReportDownloadTemplate {...this.props} />
                            </div> : this.props.isPatientReport && this.props.patientReportDownloadTemplatePastSection ? <div className='display-none reports-download-section'>
                                <PatientReportDownloadTemplatePast {...this.props} />
                            </div> : null
                        }

                        <div className={`modal fade ${this.props.isAddModal ? 'show' : ''}`} role='dialog'>
                            <div className='modal-dialog modal-dialog-centered modal-md'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h3>{this.props.modalMode == constants.popup_labels.addVisit ?
                                            this.props.currentPatient
                                                ? `${labels.patient_list_labels.new_visits} ${this.props.currentPatient.lastname},${this.props.currentPatient.firstname}` : null
                                            : null}</h3>
                                        <i className='fa fa-times fa-lg status-indicator text-danger' onClick={() => this.props.toggleVisitModal()}></i>
                                    </div>
                                    <div className='modal-body'>
                                        {this.props.siteElks ? <div className='alert alert-info'>
                                            {this.props.siteElks}
                                        </div> : null}
                                        {this.props.visitError ? <div className='alert alert-danger'>
                                            {this.props.visitError}
                                        </div> : null}
                                        {this.props.modalMode == constants.popup_labels.addVisit ? <React.Fragment>
                                            <div className='d-flex flex-row ml-5 d-flex create-visit-form'>
                                                <FormControl id='create_visit'
                                                    type='date' labelClass='col-lg-5'
                                                    inputClass='col-lg-7'
                                                    onChange={this.props.editVisit}
                                                    label={labels.patient_list_labels.visit_date}
                                                    selected={this.props.selectedVisitDate}
                                                    help={labels.patient_list_labels.add_visit_help} maxDate={addMonths(new Date(), 1)}
                                                    ref={(visit) => this.newVisit = visit} />
                                            </div>
                                            <div className='d-flex justify-end'>
                                                <FormControl type='button'
                                                    btnType='primary'
                                                    text={labels.patient_list_labels.create_visit}
                                                    className='btn-sm'
                                                    onClick={() => this.props.addNewVisit(this.props.history)} />
                                            </div>
                                        </React.Fragment> :
                                            this.props.modalMode == constants.popup_labels.addCredits ?
                                                <React.Fragment>
                                                    <div className='d-flex justify-end'>
                                                        <FormControl type='button' btnType='primary'
                                                            text={labels.credits_labels.add_credits}
                                                            className='btn-sm'
                                                            onClick={() =>
                                                                this.props.history.push(`/credits/${this.props.currentPatient.siteId}/new`)
                                                            } />
                                                    </div>
                                                </React.Fragment> :
                                                null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modal fade ${this.props.isDeleteModal ? 'show' : ''}`} role='dialog'>
                        <div className='modal-dialog'>
                            <div className='modal-content'>
                                <div className='modal-body'>
                                    <h4><b>{`${labels.patient_list_labels.delete_patient_prompt}?`}</b></h4>
                                    <br />
                                    {labels.patient_list_labels.delete_patient_desc}
                                </div>
                                <div className='modal-footer justify-space-between'>
                                    <FormControl type='button'
                                        btnType='default'
                                        text={labels.help_page_labels.cancel_btn}
                                        onClick={() => { this.props.deletePatient(false) }} />
                                    <FormControl type='button'
                                        btnType='danger'
                                        text={labels.help_page_labels.ok_btn}
                                        onClick={() => { this.props.currentPatient && this.props.deletePatient(false, this.props.currentPatient.id, this.props.history) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={`modal fade ${this.props.isBiomarkersModal ? 'show' : ''}`} role='dialog'>
                    <div className='modal-dialog'>
                        <div className='modal-content'>
                            <div className='modal-header justify-end'>
                                <i className='fa fa-times' onClick={() => this.props.toggleBiomarkersModal()}></i>
                            </div>
                            <div className='modal-body'>
                                <ReactHighcharts
                                    config={this.getBiomakersConfig(this.props.currentRow, this.props.currentPatient
                                        ? this.props.currentPatient.dob : new Date(), true)}>
                                </ReactHighcharts>
                            </div>
                        </div>
                    </div>
                </div> */}

                </ >
        )
    }
}
export default ViewPatient;