import React from 'react';
import sprite from '../../../assets/images/sprite_base.svg'
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller
} from 'react-scroll';
import labels from '../../../config/localization';
import constants from '../../../config/constants'
export default class PatientHistoryComponent extends React.Component {

    constructor(props) {
        super(props);
        this.renderHeading = this.renderHeading.bind(this);
        this.renderSubheading = this.renderSubheading.bind(this);
    }

    render_block(data) {
        return (
            Object.keys(data).map(bioInformation => (
                <div className='detail-list'>
                    <div className='title'>{bioInformation}</div>
                    <div className='item-result'>{data[bioInformation]}</div>
                </div>
            )))
    }
    render_demo_block(data) {
        return (
            Object.keys(data).map(bioInformation => (
                <div className='detail-list'>
                    <div className='title'>{constants.biographical_labels[bioInformation]}</div>
                    <div className='item-result'>{data[bioInformation]}</div>
                </div>
            )))
    }

    render_demo_block(data) {
        return (
            Object.keys(data).map(bioInformation => (
                <div className='detail-list'>
                    <div className='title'>{constants.biographical_labels[bioInformation]}</div>
                    <div className='item-result'>{data[bioInformation]}</div>
                </div>
            )))
    }
    renderSubheading() {
        if (this.props.presentPatient.gender && this.props.age_round) {
            return (
                <div className='patient-age'><span className='gender'>{this.props.presentPatient.gender}</span>, {this.props.age_round} {labels.analytics_labels.years_old}</div>
            )
        }
        else {
            return (null);
        }
    }

    renderHeading() {
        return (
            <div className='user-header'>
                <div className='user-icon'>
                    <div className='icon'>
                        <svg aria-hidden='true' title='Mariella Filip'>
                            {/* <use xlinkHref={this.props.presentPatient.gender == 'male' ? `${sprite}#male` : `${sprite}#female`} xmlnsXlink='http://www.w3.org/1999/xlink'></use> */}
                            <use xlinkHref={this.props.presentPatient.gender == 'male' ? "#male" : "#female"} xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                            <symbol id="female" viewBox="0 0 48 48">
                                <g class="nc-icon-wrapper"><path fill-rule="evenodd" clip-rule="evenodd" fill="#C6A279" d="M16.99757,31C13.6852036,31,11,28.3252907,11,25V14 c0-7.1800003,5.8199997-13,13-13s13,5.8199997,13,13v11c0,3.3155499-2.6845322,6-5.99757,6H16.99757z"></path> <rect x="18" y="23" fill-rule="evenodd" clip-rule="evenodd" fill="#EAC3A2" width="12" height="17"></rect> <path fill-rule="evenodd" clip-rule="evenodd" fill="#E0E0E0" d="M40.6829987,38.3419991L30,33l-6,6l-5.9820004-6 L7.3169999,38.3419991C5.2839999,39.3580017,4,41.4360008,4,43.7080002v2.2864037C4,46.549778,4.4492188,47,5.0087748,47h37.9824448 C43.5483589,47,44,46.5560265,44,45.9944038v-2.2864037C44,41.4360008,42.7159996,39.3580017,40.6829987,38.3419991z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#F1D9C5" d="M13,16v2c0,6.0650005,4.9350014,11,11,11s11-4.9349995,11-11v-2 c0-1.6568508-1.3431473-3-3-3c-3.4829292,0-6.5037708-1.9784393-8-4.8727989C22.5037708,11.0215607,19.4829292,13,16,13 C14.3431501,13,13,14.3431492,13,16z"></path></g>
                            </symbol>
                            <symbol id="male" viewBox="0 0 48 48">
                                <g class="nc-icon-wrapper"><rect x="18" y="24" fill-rule="evenodd" clip-rule="evenodd" fill="#EAC3A2" width="12" height="15"></rect> <path fill-rule="evenodd" clip-rule="evenodd" fill="#F1D9C5" d="M35,12v8c0,6.0650005-4.9349995,11-11,11s-11-4.9349995-11-11v-8 H35z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#72C472" d="M46,44.0092545 c0-1.6619644-1.2289314-3.5469093-2.7504997-4.2125969L30,34c-1.6879997,1.25-3.7539997,2-6,2s-4.3120003-0.75-5.9820004-2 L4.753592,39.7966576C3.232825,40.4612465,2,42.3496704,2,44.0092545v2.9878159C2,47.550972,2.4498155,48,3.001672,48h41.9966583 C45.5515404,48,46,47.5621185,46,46.9970703V44.0092545z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#444444" d="M13,11.9989014 c0-3.3131027,2.5352898-6.9043617,5.6539803-8.0181808l7.4057007-2.6448898 c0.5193291-0.1854706,1.1393986,0.0623283,1.3841782,0.551899L29,5c3.3137093,0,6,2.6930313,6,6v6c0-2.7614212-2.2385788-5-5-5H18 c-2.7614202,0-5,2.2385788-5,5V11.9989014z"></path> <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M24,36c-2.2459717,0-4.3120117-0.75-5.9819946-2 l-0.0055542,0.0024414l0,0l-2.4395752,1.0661011l1.9101353,4.6680298c0.2560425,0.625721,1.0463161,0.8223228,1.5656967,0.3895035 L24,36l4.9513283,4.1261063c0.519371,0.4328079,1.3096256,0.2362289,1.5656853-0.389473l1.9126129-4.673645L30,34 C28.3120117,35.25,26.2460327,36,24,36z"></path></g>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div className='user'>
                    <div className='patient-name'>{this.props.presentPatient.firstname} {this.props.presentPatient.lastname}</div>
                    {this.renderSubheading(this.props)}
                    <div className='history-mobile'>most recent visit: {this.props.lastVisitDate}</div>
                </div>
            </div>
        );
    }

    renderBody() {
        if (this.props.presentPatient.visits > 0 && (this.props.bioInformation || this.props.isDemo)) {
            return (this.props.bioInformation ? this.render_block(this.props.bioInformation) : this.render_demo_block(this.props.biographical_info));
        }
        else
            return (
                <div className='alert alert-danger'>{labels.analytics_labels.no_visits_label}</div>
            )
    }

    render() {
        return (
            <Element className='page-section biographical-info' id='biographical-info' name='biographical-info'>
                {this.renderInside()}
            </Element>
        )
    }

    renderInside() {
        if (this.props.age_round) {
            return (this.actuallyRender());
        }
        else {
            return (null);
        }
    }

    actuallyRender() {
        return (
            <div className='max-container with-max-width'>
                <h2 className='section-title'>{this.props.terms['biographical_information']}</h2>
                <div className='card '>
                    <div className='patient-info'>
                        {this.renderHeading()}
                        <div className='user-stats'>
                            {this.renderBody()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
